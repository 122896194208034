import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonModal,
} from "@ionic/react";

import { close } from "ionicons/icons";

import ProState from "../ProState";
import Post from "./post";

const Tags = ({ match, history }) => {
  const [show3rdModal, setShow3rdModal] = useState(false);
  const state = new ProState();
  const slug = state?.getState?.currentOpenModal || match?.params?.slug;

  state.setState = {
    currentTag: slug,
  };
  setTimeout(() => {
    if (window.location.pathname === "/") {
      window.history.pushState({}, "", `/`);
      window.history.pushState({}, "", `/tag/` + slug);
    }
  }, 0);

  const handleBack = (e) => {
    if (e) e.stopPropagation();

    if (history) history.push("/");
    window.history.pushState({}, "", "/");

    state.setState = {
      currentTag: null,
    };
    // setTimeout(() => {
    //   if (window.location.pathname !== "/") {
    //     window.history.pushState({}, "", "/");
    //     setShow3rdModal(false);
    //   }
    // }, 1000);
    setTimeout(() => {
      if (window.location.pathname !== "/") {
        window.history.pushState({}, "", "/");
      }
    }, 100);
  };
  let hasSlug = false;
  let posts = [];
  let i = 0;
  if (state?.getState?.posts) {
    state.getState.posts.forEach((post) => {
      if (post?.tags?.includes(slug)) {
        hasSlug = true;
        posts[i] = post;
        i++;
      }
    });
  }

  const handle3rdModal = (e) => {
    e.preventDefault();

    state.setState = {
      currentOpenPost: e.currentTarget.id.split("/")[1],
    };
    setShow3rdModal(true);
    window.history.pushState({}, "", e.currentTarget.id);
  };

  return (
    <>
      <IonHeader translucent mode="md">
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" onClick={handleBack} />
          </IonButtons>
          <IonTitle style={{ marginLeft: "10px" }}>
            Posts Tagged as {`${slug}`}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonModal
        isOpen={show3rdModal}
        cssClass="my-custom-class"
        swipeToClose={true}
        onDidDismiss={() => {
          setShow3rdModal(false);
          state.setState = { showBackButton: false };
          window.history.pushState({}, "", "/tag/" + slug);
        }}
      >
        <Post></Post>
        <IonButton
          mode="md"
          color="tertiary"
          style={{
            position: "fixed",
            top: "10px",
            left: "8px",
            zIndex: "10",
            fontSize: "0.3rem",
            "--box-shadow": "none",
          }}
          onClick={() => setShow3rdModal(false)}
        >
          <IonIcon icon={close}></IonIcon>
        </IonButton>
      </IonModal>

      <IonContent
        className="ion-padding"
        scrollEvents={false}
        onIonScrollStart={() => {}}
        onIonScroll={() => {
          // console.log(window.pageYOffset);
        }}
        onIonScrollEnd={() => {}}
        scrollY={true}
        scrollX={false}
      >
        {/* <IonButton onClick={() => setShowModal(true)}>Show Modal</IonButton> */}
        {/* <span style={{ textAlign: "center" }}>
          <h3>Software Engineer, Entrepreneur, UX Designer</h3>
          <p style={{ color: "var(--ion-color-medium-shade)" }}>
            Experienced tech professional with 15+ years of experience in
            building scalable apps and businesses. Expertise in user experience
            research. Working on -- Setlr and ProDevs.
          </p>
        </span> */}

        {/* <EmailList></EmailList> */}
        <IonGrid>
          <IonRow>
            {posts
              // .filter((post) => post.tags.contains(`${slug}`))
              .map((blogPost) => (
                <IonCol
                  size="12"
                  size-xs="12"
                  size-sm="6"
                  size-md="6"
                  size-lg="3"
                  size-xl="3"
                >
                  <IonCard
                    id={`/${blogPost?.slug}`}
                    key={blogPost?.slug}
                    className="card"
                    onClick={handle3rdModal}
                    style={{ cursor: "pointer" }}
                  >
                    {blogPost.image ? (
                      <img
                        loading="eager"
                        src={blogPost?.image}
                        alt={blogPost.coverImageAlt}
                      />
                    ) : (
                      <div
                        className="no-cover-image"
                        style={{ backgroundColor: `${blogPost?.bgColor}` }}
                      ></div>
                    )}
                    {blogPost?.icon ? (
                      <img
                        loading="eager"
                        className="card-icon"
                        src={`${blogPost?.icon}`}
                        alt={blogPost.coverImageAlt}
                      />
                    ) : (
                      ""
                    )}
                    <div
                      className="card-content-container"
                      style={{ padding: "10px" }}
                    >
                      <IonCardHeader>
                        <IonCardSubtitle style={{ color: "#5e5e5e" }}>
                          {blogPost?.datePretty}
                        </IonCardSubtitle>
                        <IonCardTitle>
                          {blogPost?.title_short
                            ? blogPost?.title_short
                            : blogPost?.title.substring(0, 45)}
                        </IonCardTitle>
                      </IonCardHeader>
                      <p
                        style={{ fontWeight: 500 }}
                        dangerouslySetInnerHTML={{
                          __html: `${blogPost?.content_text?.substring(
                            0,
                            60
                          )}...`,
                        }}
                      ></p>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href={`/${blogPost?.slug}`}
                      >
                        Continue reading...
                      </a>
                    </div>
                  </IonCard>
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default Tags;
