import React from "react";
import { IonButtons, IonButton, IonIcon } from "@ionic/react";
import { logoLinkedin, logoGithub } from "ionicons/icons";

const RightIcons = () => {
  return (
    <>
      <IonButtons className="right-icons" slot="end">
        <IonButton href="https://github.com/prodevsca" target="_blank">
          <IonIcon slot="start" icon={logoGithub} />
        </IonButton>
        <IonButton
          href="https://linkedin.com/in/mirabidhussain"
          target="_blank"
        >
          <IonIcon slot="start" icon={logoLinkedin} />
        </IonButton>
        {/* <IonButton>
          <IonIcon slot="start" icon={menu} />
        </IonButton> */}
      </IonButtons>
    </>
  );
};

export default RightIcons;
