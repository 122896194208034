import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButtons,
  IonAvatar,
  IonButton,
  IonIcon,
  IonModal,
  IonLabel,
} from "@ionic/react";
import { close } from "ionicons/icons";
import RightIcons from "../components/RightIcons";
import ProState from "../ProState";
import Post from "./post";
import EmailList from "../components/EmailList";
import Tags from "./tags";
const Home = ({ router }) => {
  const state = new ProState();
  const [showModal, setShowModal] = useState(false);
  const [show2ndModal, setShow2ndModal] = useState(false);
  const handleState = (e) => {
    state.setState = {
      currentOpenPost: e.currentTarget.id.split("/")[1],
      currentTag: null,
    };
    setShowModal(true);
    window.history.pushState({}, "", e.currentTarget.id.split("/")[1]);
  };

  const handleSecondModalClick = (e) => {
    state.setState = {
      currentOpenModal: e.currentTarget.id,
    };
    setShow2ndModal(true);
    window.history.pushState({}, "", "/tag/" + e.currentTarget.id);
  };

  const scrollToBottom = () => {
    let list = document.querySelector("ion-content");

    return list && list.scrollByPoint(0, 1875);
  };
  // setTimeout(() => {
  //   scrollToBottom();
  // }, 2000);

  return (
    <>
      <IonHeader translucent mode="md">
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonButton routerLink={`/`}>
              <IonAvatar>
                <img
                  alt="Logo"
                  src="https://p119.p3.n0.cdn.getcloudapp.com/items/GGu2oyBB/abid.jpeg?v=c4c7d6b9e8e6f5740609c694942fc10d"
                />
              </IonAvatar>
            </IonButton>
          </IonButtons>
          <IonTitle>Mir Abid Hussain</IonTitle>
          <RightIcons></RightIcons>
        </IonToolbar>
      </IonHeader>

      <IonModal
        isOpen={showModal}
        cssClass="my-custom-class"
        swipeToClose={true}
        presentingElement={router || undefined}
        onDidDismiss={() => {
          setShowModal(false);
          state.setState = { showBackButton: false };
          if (window.location.pathname !== "/") {
            window.history.pushState({}, "", "/");
          }
        }}
        text="about"
      >
        <Post></Post>
        <IonButton
          mode="md"
          color="tertiary"
          style={{
            position: "fixed",
            top: "10px",
            left: "8px",
            zIndex: "10",
            fontSize: "0.3rem",
            "--box-shadow": "none",
            width: "50px",
          }}
          onClick={() => setShowModal(false)}
        >
          <IonIcon icon={close}></IonIcon>
        </IonButton>
      </IonModal>

      <IonModal
        isOpen={show2ndModal}
        cssClass="my-custom-class"
        onDidDismiss={() => {
          setShow2ndModal(false);
          state.setState = { showBackButton: false };
          if (window.location.pathname !== "/") {
            window.history.pushState({}, "", "/");
          }
        }}
      >
        <Tags></Tags>
        <IonButton
          mode="md"
          color="tertiary"
          style={{
            position: "fixed",
            top: "10px",
            left: "8px",
            zIndex: "10",
            fontSize: "0.3rem",
            "--box-shadow": "none",
            width: "50px",
          }}
          onClick={() => setShow2ndModal(false)}
        >
          <IonIcon icon={close}></IonIcon>
        </IonButton>
      </IonModal>
      <IonContent
        className="ion-padding"
        scrollEvents={false}
        onIonScrollStart={() => {}}
        onIonScroll={() => {
          // console.log(window.pageYOffset);
          document
            .querySelector("ion-content")
            .getScrollElement()
            .then((scroll) => {
              console.dir(scroll.scrollTop);
            });
        }}
        onIonScrollEnd={() => {}}
        scrollY={true}
        scrollX={false}
      >
        {/* <IonButton onClick={() => setShowModal(true)}>Show Modal</IonButton> */}
        <span style={{ textAlign: "center" }}>
          <h3>Software Engineer, UX Designer</h3>
          <p
            style={{
              color: "var(--ion-color-medium-shade)",
              maxWidth: "700px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Experienced tech professional with 10+ years of experience in
            building scalable apps and businesses.
          </p>
        </span>

        <EmailList></EmailList>
        <div className="tags-container" style={{ marginTop: "40px" }}>
          <div className="tags-container-posiion">
            <IonButton
              color="tertiary"
              fill="clear"
              id={`Node.JS`}
              onClick={handleSecondModalClick}
            >
              Node.JS
            </IonButton>
            <IonButton
              color="tertiary"
              fill="clear"
              id={`CSS`}
              onClick={handleSecondModalClick}
            >
              CSS
            </IonButton>
            <IonButton
              color="tertiary"
              fill="clear"
              id={`Javascript`}
              onClick={handleSecondModalClick}
            >
              JavaScript
            </IonButton>
            <IonButton
              color="tertiary"
              fill="clear"
              id={`design`}
              onClick={handleSecondModalClick}
            >
              Design
            </IonButton>
            <IonButton
              disabled={true}
              color="tertiary"
              fill="clear"
              id={`photography`}
              style={{ border: "none" }}
              onClick={(e) => e.preventDefault()}
            ></IonButton>
          </div>
        </div>
        <IonGrid>
          <IonRow>
            {state?.getState?.posts?.map((blogPost) => (
              <IonCol
                size="12"
                size-xs="12"
                size-sm="6"
                size-md="6"
                size-lg="3"
                size-xl="3"
              >
                <IonCard
                  id={`/${blogPost?.slug}`}
                  key={blogPost?.slug}
                  className="card"
                  onClick={handleState}
                  style={{ cursor: "pointer" }}
                >
                  {blogPost?.image ? (
                    <img
                      loading="eager"
                      src={blogPost?.image}
                      alt={blogPost.coverImageAlt}
                    />
                  ) : (
                    <div
                      className="no-cover-image"
                      style={{ backgroundColor: `${blogPost?.bgColor}` }}
                    ></div>
                  )}

                  {blogPost?.icon ? (
                    <img
                      loading="eager"
                      className="card-icon"
                      src={`${blogPost?.icon}`}
                      alt={blogPost.coverImageAlt}
                    />
                  ) : (
                    ""
                  )}

                  <div
                    className="card-content-container"
                    style={{ padding: "10px" }}
                  >
                    <IonCardHeader>
                      <IonCardSubtitle style={{ color: "#5e5e5e" }}>
                        {blogPost?.datePretty}
                      </IonCardSubtitle>
                      <IonCardTitle>
                        {blogPost?.title_short
                          ? blogPost?.title_short
                          : blogPost?.title.substring(0, 45)}
                      </IonCardTitle>
                    </IonCardHeader>
                    <p
                      style={{ fontWeight: 500 }}
                      dangerouslySetInnerHTML={{
                        __html: `${blogPost?.content_text?.substring(
                          0,
                          60
                        )}...`,
                      }}
                    ></p>
                  </div>
                  <a
                    onClick={(e) => e.preventDefault()}
                    href={`/${blogPost?.slug}`}
                  >
                    Continue reading...
                  </a>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonLabel className="credits">
              © Mir Abid Hussain <br /> <br />{" "}
              <IonButton
                id="/terms"
                color="tertiary"
                style={{ marginTop: "-10px" }}
                fill="clear"
                onClick={handleState}
              >
                Terms
              </IonButton>
              <span>|</span>
              <IonButton
                id="/privacy"
                color="tertiary"
                style={{ marginTop: "-10px" }}
                fill="clear"
                onClick={handleState}
              >
                Privacy
              </IonButton>
              <br /> Proudly built in Canada
              <svg
                style={{
                  marginBottom: "-10px",
                  marginTop: "3px",
                  marginLeft: "2px",
                }}
                data-name="Layer 1"
                viewBox="0 0 390 390"
                alt="Maple leaf"
                width="30px"
              >
                <path
                  fill="var(--ion-color-tertiary)"
                  d="M390 226.6c0-7.44-31.36-5.41-33.85-10.83s34.75-49.66 29.33-59.58-55.1 12.63-58.23 7.67 3.17-37.92-2.25-42-56.88 53.25-64.1 46.49 16.7-113.75 11.76-115.9-37 14.8-41.09 13.89S202.39-.04 195-.04s-32.52 65.5-36.57 66.4-36.15-16-41.09-13.89 19 109.13 11.76 115.9-58.68-50.55-64.1-46.49.87 37-2.25 42-52.82-17.61-58.23-7.67 31.83 54.16 29.33 59.58S0 219.16 0 226.6s91.41 56.72 97.5 62.52-31.57 30.35-26.18 35c6.24 5.38 41.6-9 112.85-10.36v65.43a10.83 10.83 0 0 0 21.66 0v-65.45c71.25 1.37 106.61 15.74 112.85 10.36 5.38-4.63-32.28-29.18-26.18-35s97.5-55.05 97.5-62.5"
                  style={{ userSelect: "auto" }}
                ></path>
              </svg>
            </IonLabel>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default Home;
