import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import ProState from "../ProState";
import Comments from "../components/Comments";
import JSONPost from "../components/JSONPost";

const Post = ({ match, history, text }) => {
  const state = new ProState();

  const slug = match?.params?.slug || state.getState.currentOpenPost;

  let post = state.getState.posts.filter((post) => post.slug === slug)[0];
  if (!post)
    post = state.getState.pages.filter((page) => page.slug === slug)[0];

  state.setState = {
    JSONPost: post?.content_json,
  };

  let postSlugs;
  if (post) postSlugs = [post.slug];
  if (!post) postSlugs = [];
  const postDoesNotExist = postSlugs.indexOf(slug) === -1;

  if (postDoesNotExist) return <Redirect to="/404" />;

  setTimeout(() => {
    if (window.location.pathname === "/") {
      window.history.pushState({}, "", `/`);
      window.history.pushState({}, "", slug);
    }
  }, 0);
  const handleBack = (e) => {
    if (e) e.preventDefault();
    if (state.getState.currentTag) {
      window.history.pushState({}, "", "/tag/" + state.getState.currentTag);
      if (history) history.push("/tag/" + state.getState.currentTag);

      setTimeout(() => {
        if (window.location.pathname !== "/tag/" + state.getState.currentTag) {
          window.history.pushState({}, "", "/tag/" + state.getState.currentTag);
        }
      }, 100);
    } else {
      state.setState = {
        currentTag: null,
      };
      if (history) history.push("/");

      setTimeout(() => {
        if (window.location.pathname !== "/") {
          window.history.pushState({}, "", "/");
        }
      }, 100);
    }
  };
  return (
    <>
      <IonHeader translucent mode="md">
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" onClick={handleBack} />
          </IonButtons>

          <IonTitle style={{ marginLeft: "10px" }}>
            {/* Posted in {`${post.category}`} */}
            {post?.title_short ? `${post?.title_short}` : `${post?.title}`}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        {post?.image ? (
          <img
            style={{ minHeight: "40vh" }}
            src={post?.image}
            alt={post?.coverImageAlt}
            loading="lazy"
          />
        ) : post?.icon ? (
          ""
        ) : (
          // <div
          //   style={{
          //     backgroundColor: post.bgColor,
          //     height: "40vh",
          //     position: "relative",
          //   }}
          // >
          //   <IonIcon
          //     style={{
          //       position: "absolute",
          //       top: "50%",
          //       left: "50%",
          //       transform: "translate(-50%,-50%)",
          //       width: "200px",
          //       height: "200px",
          //     }}
          //     icon={post.icon}
          //   ></IonIcon>
          // </div>
          ""
        )}

        {post?.content_html ? (
          <article
            className="ion-padding"
            dangerouslySetInnerHTML={{
              __html: `${post?.content_html}`,
            }}
          ></article>
        ) : (
          ""
        )}
        {/* <section>
          <Output data={data} />
        </section> */}
        {post?.content_json ? (
          <JSONPost data={post?.content_json}></JSONPost>
        ) : (
          ""
        )}
        <Comments></Comments>
      </IonContent>
    </>
  );
};

export default Post;
