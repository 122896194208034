import React, { useEffect } from "react";
import ProState from "../ProState";

const Comments = () => {
  useEffect(() => {
    const state = new ProState();
    let script;
    if (!window.DISQUS) {
      script = document.createElement("script");

      script.src = "https://mir-abid-husssains-blog.disqus.com/embed.js";
      script.setAttribute("data-timestamp", +new Date());
      script.async = true;
      script.defer = true;
      document.body && document.body.appendChild(script);
      setTimeout(() => {
        if (!window.DISQUS) {
          document.body && document.body.appendChild(script);
        } else {
          try {
            window.DISQUS.reset({
              reload: true,
              config: () => {
                this.page.identifier = state.getState.currentOpenModal;
                this.page.url = "/" + state.getState.currentOpenModal;
              },
            });
          } catch (err) {}
        }
      }, 1000);
    } else {
      setTimeout(() => {
        try {
          window.DISQUS.reset({
            reload: true,
            config: () => {
              this.page.identifier = state.getState.currentOpenModal;
              this.page.url = "/" + state.getState.currentOpenModal;
            },
          });
        } catch (err) {
          // console.log(err);
          setTimeout(() => {
            try {
              window.DISQUS.reset({
                reload: true,
                config: () => {
                  this.page.identifier = state.getState.currentOpenModal;
                  this.page.url = "/" + state.getState.currentOpenModal;
                },
              });
            } catch (err) {
              // console.log(err);
              if (!window.DISQUS) {
                document.body && document.body.appendChild(script);
              }
            }
          }, 2000);
        }
      }, 0);
    }

    // return () => {
    //   console.log("useEffect done");
    // };
  }, []);
  return (
    <>
      <div
        style={{
          padding: "20px",
          paddingTop: "0px",
          marginBottom: "30px",
          maxWidth: "750px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        id="disqus_thread"
      ></div>
    </>
  );
};

export default Comments;
