import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import "./App.css";
import { IonPage, IonApp, IonRouterOutlet } from "@ionic/react";
import blogPostsStr from "./data/BlogPosts";
import blogPagesStr from "./data/BlogPages";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/home";
import Post from "./pages/post";
import NoMatch from "./pages/no-match";
import ProState from "./ProState";
import Tags from "./pages/tags";
import createHistory from "history/createBrowserHistory";

const history = createHistory();
const generateRandomColor = () => {
  let r = Math.round(Math.random() * 255); //red 0 to 255
  let g = Math.round(Math.random() * 255); //green 0 to 255
  let b = Math.round(Math.random() * 255); //blue 0 to 255
  return "rgb(" + r + ", " + g + ", " + b + ")";
};

if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
  document.getElementsByTagName("body")[0].classList.add("dark");
  document.getElementsByTagName("body")[0].classList.remove("light");
}

if (window.matchMedia("(prefers-color-scheme: light)").matches) {
  document.getElementsByTagName("body")[0].classList.add("light");
  document.getElementsByTagName("body")[0].classList.remove("dark");
}

const App = () => {
  window.history.pushState({}, "", "/");

  useEffect(() => {
    const blogPosts = JSON.parse(blogPostsStr);
    const blogPages = JSON.parse(blogPagesStr);
    new ProState({
      posts: blogPosts.items.sort(
        (a, b) => new Date(b.date_published) - new Date(a.date_published)
      ),
      pages: blogPages,
    });

    if (blogPosts) {
      blogPosts.items.forEach((post) => {
        if (!post.image) {
          post.bgColor = generateRandomColor();
        }
      });
    }
  }, []);
  return (
    <IonReactRouter history={history}>
      <IonApp>
        <IonPage>
          <IonRouterOutlet>
            <Route exact path="/" component={Home} />
            <Route path="/:slug" component={Post} />
            <Route path="/tag/:slug" component={Tags} />
            <Route exact path="/tag/null" component={Home} />
          </IonRouterOutlet>
          <Route path="/404" component={NoMatch} />
        </IonPage>
      </IonApp>
    </IonReactRouter>
  );
};

export default App;
