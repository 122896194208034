let blogPagesStr = [
  {
    bgColor: "transparent",
    title: "Terms of Use",
    content_html:
      '<p>Copyright © 2020 Mir Abid Hussain. All Rights reserved. All content contained herein this blog is provided "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHOR(S) OR COPYRIGHT HOLDER(S) BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE BLOGGING SOFTWARE OR CONTENT IN THE BLOG OR THE USE OR OTHER DEALINGS IN THE BLOGGING SOFTWARE OR CONTENT IN THE BLOG.</p><br /> <h3>Commenting Policy</h3><p>Be kind, gentle and nice in the comments. No vulgar, sexual, racist or bigotted comments please. Only discussions releavant to the topic of the post in question are allowed. Comments are not always answered. Questions and constructive suggestions/criticism welcome.</p>',
    slug: "terms",
    dateFormatted: "2019-08-01",
    datePretty: "August 1st, 2019",
  },
  {
    bgColor: "transparent",
    title: "Privacy Policy",
    content_html:
      '<p>Please refer to <a href="https://help.disqus.com/en/articles/1717103-disqus-privacy-policy" target="_blank">Disqus\'s Privacy Policy</a> for more information on their privacy policy. All comments are posted via Disqus.<p>',
    slug: "privacy",
    dateFormatted: "2019-08-01",
    datePretty: "August 1st, 2019",
  },
];

blogPagesStr = JSON.stringify(blogPagesStr);
export default blogPagesStr;
