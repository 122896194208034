const ProState = class State {
  constructor(newState) {
    if (newState) this.state = newState;
    if (!!State.instance) {
      return State.instance;
    }

    State.instance = this;
    return this;
  }

  set setState(state) {
    this.state = { ...this.state, ...state };
  }

  get getState() {
    return this.state;
  }
};

export default ProState;
