import React from "react";
import { Link } from "react-router-dom";
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButtons,
  IonBackButton,
} from "@ionic/react";

const NoMatch = ({ history }) => {
  const handleBack = () => {
    if (window.location.pathname !== "/") {
      if (history) history.push("/");
    }
    setTimeout(() => {
      if (window.location.pathname !== "/") {
        window.history.pushState({}, "", "/");
      }
    }, 1000);
  };
  return (
    <>
      <IonHeader translucent mode="md">
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" onClick={handleBack} />
          </IonButtons>
          <IonTitle>Not Found</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        class="ion-padding"
        scrollEvents={false}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        <h1>Hmmm... can't seem to find that page.</h1>
        <Link to="/" onClick={handleBack}>
          Take me home
        </Link>
      </IonContent>
    </>
  );
};

export default NoMatch;
