import React, { useEffect } from "react";
import { IonCard, IonButton, IonInput } from "@ionic/react";

const EmailList = ({ history }) => {
  useEffect(() => {
    if (!window.mc) {
      const script = document.createElement("script");

      script.src =
        "https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
      script.async = true;
      script.defer = true;
      try {
        document.body && document.body.appendChild(script);
      } catch (err) {
        // console.log(err);
        setTimeout(() => {
          if (!window.mc) {
            document.body && document.body.appendChild(script);
          }
        }, 500);
      }

      //   const link = document.createElement("link");
      //   link.href =
      //     "https:////cdn-images.mailchimp.com/embedcode/classic-10_7.css";
      //   link.rel = "stylesheet";
      //   link.type = "text/css";
      //   document.body && document.body.appendChild(link);

      //   setTimeout(() => {
      //     (function ($) {
      //       window.fnames = [];
      //       window.ftypes = [];
      //       window.fnames[0] = "EMAIL";
      //       window.ftypes[0] = "email";
      //       window.fnames[1] = "FNAME";
      //       window.ftypes[1] = "text";
      //       window.fnames[2] = "LNAME";
      //       window.ftypes[2] = "text";
      //       window.fnames[3] = "ADDRESS";
      //       window.ftypes[3] = "address";
      //       window.fnames[4] = "PHONE";
      //       window.ftypes[4] = "phone";
      //       window.fnames[5] = "BIRTHDAY";
      //       window.ftypes[5] = "birthday";
      //     })(window.jQuery);
      //     var $mcj = window.jQuery.noConflict(true);
      //   }, 1000);
    }
    return () => {
      // console.log("useEffect done");
    };
  }, []);

  return (
    <>
      <IonCard
        id="mc_embed_signup"
        style={{
          marginTop: "30px",
          maxWidth: "300px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "20px",
        }}
      >
        {/* <h3 style={{ textAlign: "center" }}>Subscribe</h3> */}
        <form
          action="https://mirabidhussain.us7.list-manage.com/subscribe/post?u=28c9f0d5b72a2e89950d9cb8e&amp;id=88dbba1c28"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll" style={{ display: "flex" }}>
            {/* <div className="indicates-required">
                <span className="asterisk">*</span> indicates required
              </div> */}
            <div className="mc-field-group">
              {/* <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label> */}
              <IonInput
                autoFocus
                style={{ width: "170px", marginLeft: "10px" }}
                type="email"
                defaultValue=""
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                placeholder="email@example.com"
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    document.querySelector("#mc-embedded-subscribe").click();
                  }
                }}
              />
            </div>

            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                readOnly
                type="text"
                name="b_28c9f0d5b72a2e89950d9cb8e_88dbba1c28"
                tabIndex="-1"
                value=""
              />
            </div>
            <div className="clear">
              <IonButton
                color="tertiary"
                mode="md"
                fill="clear"
                slot="end"
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                style={{ position: "absolute", right: "0px" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  window.$("#mc-embedded-subscribe-form").submit();
                }}
              >
                Subscribe{" "}
              </IonButton>
            </div>
          </div>
          <div
            id="mce-responses"
            className="clear"
            style={{ marginLeft: "10px" }}
          >
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
        </form>
      </IonCard>
    </>
  );
};

export default EmailList;
