import React from "react";
import Output from "editorjs-react-renderer";
import ProState from "../ProState";

const JSONPost = () => {
  const state = new ProState();
  const data = state?.getState?.JSONPost;
  const styles = {
    header: {
      textRendering: "optimizeLegibility",
      fontFamily: "sans-serif",
    },
    paragraph: {
      marginBottom: "5px",
      marginTop: "20px",
      textRendering: "optimizeLegibility",
      lineHeight: "1.6",
      fontFamily: "sans-serif",
      fontWeight: "300",
      fontSize: "1.25rem",
    },
    list: {
      marginLeft: "20px",
      textRendering: "optimizeLegibility",
      lineHeight: "1.35",
      fontFamily: "sans-serif",
      fontWeight: "300",
      fontSize: "1.20rem",
    },
    image: {
      img: {
        objectFit: "contain",
      },
    },
  };
  // const data = fetch(url)
  //   .then(function (response) {
  //     console.log(response);
  //     return response.json();
  //   })
  //   .then(function (myJson) {
  //     console.log(myJson);
  //   });

  return (
    <>
      <section style={{ padding: "25px" }}>
        <Output data={data} style={styles} />
      </section>
    </>
  );
};
export default JSONPost;
